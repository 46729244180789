import { render, staticRenderFns } from "./cabinet-event-settings.html?vue&type=template&id=5f1c2323&scoped=true&"
import script from "./cabinet-event-settings.vue?vue&type=script&lang=ts&"
export * from "./cabinet-event-settings.vue?vue&type=script&lang=ts&"
import style0 from "@/_modules/promo/components/side-bar-left/side-bar-left.scss?vue&type=style&index=0&id=5f1c2323&scoped=true&lang=scss&"
import style1 from "./cabinet-event-settings.scss?vue&type=style&index=1&id=5f1c2323&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f1c2323",
  null
  
)

export default component.exports