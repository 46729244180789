


import Component, { mixins } from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import _isEqual from 'lodash.isequal';
import { TranslateResult } from 'vue-i18n';
import { MENU_PROPERTIES_KEY_NAME } from '@/_modules/promo-cabinet/components/cabinet-event-settings/cabinet-event-settings.vue';
import NotificationsMixin from '@/_mixins/notifications.mixin.ts';
import { TEvent, TEventSettings } from '@/_types/event.type';

import iconHome from '@/_modules/icons/components/sidebar/icon-home.vue';
import iconInfo from '@/_modules/icons/components/sidebar/icon-info.vue';
import IconProgram from '@/_modules/icons/components/sidebar/icon-program.vue';
import iconHall from '@/_modules/icons/components/sidebar/icon-hall.vue';
import iconContacts from '@/_modules/icons/components/sidebar/icon-contacts.vue';
import iconMeetings from '@/_modules/icons/components/sidebar/icon-meetings.vue';
import iconDiscussions from '@/_modules/icons/components/sidebar/icon-discussions.vue';
import iconNotes from '@/_modules/icons/components/sidebar/icon-notes.vue';
import iconResult from '@/_modules/icons/components/sidebar/icon-result.vue';
import iconTextChats from '@/_modules/icons/components/sidebar/icon-text-chats.vue';
import iconNews from '@/_modules/icons/components/sidebar/icon-news.vue';

export type TSideBarLeftMenuItem = {
  routeName: string;
  routeQuery?: any;
  active: boolean;
  isActive?: () => boolean;
  isDisabled?: () => boolean;
  title?: TranslateResult;
  iconComponentName?: string;
  sorting?: number;
}

@Component({
  components: {
    iconHome,
    iconInfo,
    IconProgram,
    iconHall,
    iconContacts,
    iconMeetings,
    iconDiscussions,
    iconNotes,
    iconResult,
    iconTextChats,
    iconNews,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      isEventLoading: '_eventStore/isLoading',
      eventSettings: '_eventStore/eventSettings',
      isEventSettingsPolled: '_eventStore/isEventSettingsPolled',
      isAuthenticated: 'authStore/isAuthenticated',
      meetingsCount: 'notificationsStore/meetingsCount',
      noticedMeetingsCount: 'notificationsStore/noticedMeetingsCount',
      messagesCount: 'notificationsStore/messagesCount',
      noticedMessagesCount: 'notificationsStore/noticedMessagesCount',
    }),
  },
})
export default class PromoSideBarLeft extends mixins(NotificationsMixin) {

  public menuItems: TSideBarLeftMenuItem[] = [
    {
      routeName: 'event-info',
      active: true,
      isActive: (): boolean => this.$route.name === 'event-info',
      isDisabled: (): boolean => this.isEventLoading,
      title: this.$t('sideBar.info'),
      iconComponentName: 'icon-home',
    },
    {
      routeName: 'promo-live',
      active: true,
      isActive: (): boolean => this.$route.name === 'promo-live',
      isDisabled: (): boolean => this.isEventLoading,
      title: this.$t('sideBar.live'),
      iconComponentName: 'icon-info',
    },
    {
      routeName: 'news',
      active: true,
      isActive: (): boolean => this.$route.name === 'news',
      isDisabled: (): boolean => this.isEventLoading,
      title: this.$t('sideBar.news'),
      iconComponentName: 'icon-news',
    },
    {
      routeName: 'promo-program',
      active: true,
      isActive: (): boolean => { return this.$route.meta && this.$route.meta.isProgramRoute; },
      isDisabled: (): boolean => this.isEventLoading,
      title: this.$t('sideBar.program'),
      iconComponentName: 'icon-program',
    },
    {
      routeName: 'promo-page-events-companies',
      active: true,
      isActive: (): boolean => (this.$route.name.indexOf('promo-page-events') === 0 || this.$route.name === 'promo-profile') && (this.$route.name !== 'promo-contacts'),
      isDisabled: (): boolean => this.isEventLoading,
      title: this.$t('sideBar.hall'),
      iconComponentName: 'icon-hall',
    },
    {
      routeName: 'promo-contacts',
      active: true,
      isActive: (): boolean => (this.$route.name.indexOf('promo-page-contacts') === 0) || (this.$route.name === 'promo-contacts') || (this.$route.name.indexOf('promo-contact-') > -1),
      isDisabled: (): boolean => this.isEventLoading,
      title: this.$t('sideBar.contacts'),
      iconComponentName: 'icon-contacts',
      routeQuery: {},
    },
    {
      routeName: 'text-chats',
      active: true,
      isActive: (): boolean => this.$route.name === 'text-chats' || this.$route.name === 'text-chat-expanded',
      isDisabled: (): boolean => this.isEventLoading,
      title: this.$t('sideBar.textChats'),
      iconComponentName: 'icon-text-chats',
    },
    {
      routeName: 'notes-list',
      active: true,
      isActive: (): boolean => this.$route.name === 'notes-list',
      isDisabled: (): boolean => this.isEventLoading,
      title: this.$t('sideBar.notes'),
      iconComponentName: 'icon-notes',
    },
    {
      routeName: 'promo-page-calendar',
      active: true,
      isActive: (): boolean => this.$route.name.indexOf('promo-page-calendar') > -1,
      isDisabled: (): boolean => this.isEventLoading,
      title: this.$t('sideBar.calendar'),
      iconComponentName: 'icon-meetings',
    },
    {
      routeName: 'result',
      active: true,
      isActive: (): boolean => this.$route.name === 'result-personal' || this.$route.name === 'result-company' || this.$route.name === 'result-event',
      isDisabled: (): boolean => this.isEventLoading,
      title: this.$t('sideBar.result'),
      iconComponentName: 'icon-result',
    },
  ];
  public routeNamesToSettingsKeys: {[key: string]: string} = { // AW-2349
    'event-info': 'event_info',
    'promo-live': 'promo_live',
    news: 'news',
    'promo-program': 'promo_program',
    'promo-page-events-companies': 'promo_page_events_companies',
    'promo-page-calendar': 'promo_page_calendar',
    'notes-list': 'notes_list',
    'text-chats': 'text_chats',
    'promo-contacts': 'promo_contacts',
    result: 'result',
  }

  public readonly event: TEvent;
  public readonly isEventLoading: boolean;
  public readonly isAuthenticated: boolean;
  public readonly meetingsCount: number;
  public readonly noticedMeetingsCount: number;
  public readonly messagesCount: number;
  public readonly eventSettings: TEventSettings;
  public readonly isEventSettingsPolled: boolean;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get initMenu(): TSideBarLeftMenuItem[] {

    this.menuItems.forEach((x, index) => {
      if(this.menuItemSorting(x)) {
        this.menuItems[index].sorting = this.menuItemSorting(x);
      }
    });

    return this.menuItems.sort((a, b) => {
      if (a.sorting < b.sorting) {
        return -1;
      } else if (a.sorting > b.sorting) {
        return 1;
      }
      return 0;
    });
  }

  public get activeMenuItems(): TSideBarLeftMenuItem[] {
    return this.initMenu.filter((x) => {
      return x.active && this.isMenuItemShown(x);
    });
  }

  @Prop({ type: Boolean, default: false })
  public readonly accessCheckNecessary: boolean;

  @Prop({ type: String, default: '' })
  public readonly gotoProp: string; // TODO: looks like it is not being used, check and remove

  @Watch('eventSettings')
  private onDefaultScreenChange(): void {

    const defaultScreen = this.eventSettings.layout.defaultScreen;

    let routeName = '';
    Object.entries(this.routeNamesToSettingsKeys).find(params => {
      if(params[1] === defaultScreen) {
        routeName = params[0];
      }
    });

    if (this.$route.name === 'event-info') {
      if (!routeName || ((routeName === this.$route.name))) {
        return;
      }

      this.$emit('promoGoto', routeName);

      // For showPromoAccessPopup
      if (this.accessCheckNecessary) {
        return;
      }

      try {
        this.$router.push({
          name: routeName,
          params: {eventId: this.$route.params.eventId},
        }).catch(() => {
          /* ignore */
        });
      } catch {
        /* ignore */
      }
    }
  }

  public mounted(): void {
    this.checkEventIsEnabled();
    this.$store.dispatch('sideBarLeftStore/close');
  }

  private checkEventIsEnabled(): void {

    if (this.$route.name === 'event-info') {
      return;
    }

    if (!this.event || !this.event.personal) {
      window.setTimeout(() => {
        this.checkEventIsEnabled();
      }, 250);
      return;
    }
    if (this.isEventAccessEnabled() === false) {
      this.handleEventAccessDisabled();
    }
  }

  private async handleEventAccessDisabled(): Promise<void> {
    await this.$store.dispatch('eventStore/showEventAccessDisabledPopup');

    try {
      this.$router.push({
        name: 'event-info',
        params: { eventId: this.$route.params.eventId }
      });
    } catch {
      /* ignore */
    }
  }

  private isEventAccessEnabled(): boolean {
    return this.event.personal.is_creator || this.event.is_enabled;
  }

  private isRouteQueryEqual(menuItem: TSideBarLeftMenuItem): boolean {
    return (!this.$route.query || !menuItem.routeQuery || _isEqual(this.$route.query, menuItem.routeQuery));
  }

  private menuItemClickHandler(event: Event, item: TSideBarLeftMenuItem): void {

    event.preventDefault();

    const routeName: string = item.routeName;

    if (!routeName || ((routeName === this.$route.name) && this.isRouteQueryEqual(item))) {
      return;
    }

    this.$emit('promoGoto', routeName);

    // For showPromoAccessPopup
    if (this.accessCheckNecessary) {
      return;
    }

    try {
      this.$router.push({
        name: routeName,
        params: { eventId: this.$route.params.eventId },
        query: item.routeQuery || undefined,
      }).catch(() => {
        /* ignore */
      });
    } catch {
      /* ignore */
    }
  }

  private hoverHandler(event: MouseEvent): void {
    if (!event || !event.type) {
      return;
    }

    if (event.type === 'mouseenter') {
      this.$store.dispatch('sideBarLeftStore/open');
    } else if (event.type === 'mouseleave') {
      this.$store.dispatch('sideBarLeftStore/close');
    }

  }

  private isMenuItemShown(menuItem: TSideBarLeftMenuItem): boolean {
    if (!this.eventSettings || !this.eventSettings.layout || !this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME]) {
      return true;
    }
    const menuItemRoute = this.routeNamesToSettingsKeys[menuItem.routeName]; // AW-2349
    if (!Object.prototype.hasOwnProperty.call(this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME], menuItemRoute)) {
      return true;
    }

    return this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME][menuItemRoute].isShown;

  }

  private menuItemSorting(menuItem: TSideBarLeftMenuItem): number {

    const menuItemRoute = this.routeNamesToSettingsKeys[menuItem.routeName]; // AW-2349
    if (this.eventSettings && !Object.prototype.hasOwnProperty.call(this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME], menuItemRoute)) {
      return null;
    }
    return this.eventSettings && this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME][menuItemRoute].sorting;
  }

  private isCalendarBadgeNotificationNeeded(item: TSideBarLeftMenuItem): boolean {
    return (item.routeName === 'promo-page-calendar') && (this.meetingsCount - this.noticedMeetingsCount > 0);
  }
}
