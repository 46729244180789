<template>
  <div class="location-picker" :class="{'none' : isHidden}">
    <div class="location-picker-map" ref="map"></div>
    <input :class="{'none' : !hasPicker}" v-model="input_address" type="text" class="location-picker-autocomplete"
           ref="input"/>
  </div>
</template>


<script>
import {mapState} from 'vuex'
import { startGoogleMaps } from '@/services/googleMaps';

export default {
  props: {
    mapData: {
      type: Object
    },
    hasPicker: {
      type: Boolean,
      default: false
    },
    address: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(
      "eventStore", ["page_status"]
    )
  },
  data() {
    return {
      geocoder: null,
      map: null,
      marker: null,
      autocomplete: null,
      place: null,
      isHidden: false,
      input_address: ''
    }
  },

  watch: {
    page_status(newValue) { // , oldvalue
      if (newValue === 'ready') {
        this.initGMaps({});
      }
    },
  },
  methods: {
    showMap() {
      this.isHidden = false;
    },
    hideMap() {
      this.isHidden = true;
    },
    queryGoogleMapsPlace(results, status) {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        this.showMap();
        this.map.setCenter(results[0].geometry.location)
        this.map.setZoom(15)
        this.marker.setPosition(results[0].geometry.location)
      } else {
        this.hideMap();
      }
    },
    initGMaps(options) {
      if(typeof options == 'undefined'){
        options = {};
      }
      this.geocoder = new window.google.maps.Geocoder()

      this.map = new window.google.maps.Map(this.$refs.map, Object.assign({
        center: {lat: 0, lng: 0},
        zoom: 15,
        disableDefaultUI: true
      }, options))

      this.marker = new window.google.maps.Marker(Object.assign({
        map: this.map,
        position: this.map.getCenter(),
        draggable: this.hasPicker
      }, options.marker))

      this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs.input, Object.assign({
        types: ['geocode']
      }, options.autocomplete))
      this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(this.$refs.input)

      // GMaps events, see https://developers.google.com/maps/documentation/javascript/events
      if (this.hasPicker) {
        this.marker.addListener('dragend', this.geocodeLocation)
      }
      this.autocomplete.addListener('place_changed', this.moveMarker)

      this.updateGMaps();

    },
    updateGMaps() {
      if (this.mapData && this.mapData.Latitude && this.mapData.Longitude) {
        // if event has Latitude, Longitude,
        // using them only
        let coords = new window.google.maps.LatLng(
          parseFloat(this.mapData.Latitude),
          parseFloat(this.mapData.Longitude)
        );
        this.map.panTo(coords);
        this.map.setCenter(coords);
        this.marker.setPosition(coords)
      } else if (this.address) {
        // if prop 'address' is set, using it to set the map position
        // on event card, for example.
        this.input_address = this.address;
        let request = {
          query: this.address,
          fields: ['name', 'geometry']
        };

        let service = new window.google.maps.places.PlacesService(this.map);

        service.findPlaceFromQuery(request, this.queryGoogleMapsPlace);
      }
    },
    geocodeLocation(e) {
      this.map.panTo(e.latLng)
      this.$refs.input.value = ''

      this.geocoder.geocode({'latLng': e.latLng}, (response) => {
        if (response && response.length > 0) {
          this.place = response[0]
        } else {
          this.place = null
        }
      })
    },
    moveMarker() {
      let place = this.autocomplete.getPlace()
      let location = place.geometry && place.geometry.location
      if (location) {
        this.place = place
        this.map.panTo(location)
        this.marker.setPosition(location)
      }
    }
  },
  mounted() {
    this.input_address = this.address;
    startGoogleMaps().then(this.initGMaps);
  }
}
</script>


<style lang="scss" scoped>
  .location-picker,
  .location-picker-map {
    height: 100%;
    min-height: 200px;
  }

  .location-picker-autocomplete {
    padding: 7px 14px;
    margin: 10px;
    width: 30%;
    min-width: 200px;
    font-size: 16px;
    font-weight: 300;
    text-overflow: ellipsis;
    border: 0;
    border-radius: 2px 0 0 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }

  .location-picker > .location-picker-autocomplete {
    display: none;
  }
</style>
