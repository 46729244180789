



import { mapGetters, mapState } from 'vuex';
import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import DateTimeHelper from '@/_helpers/date-time.helper';
import { TEvent, TEventSettings } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import EventLanguageSelector from '@/_components/create-event/event-language-selector.vue';
import timezone from '@/_modules/events/components/timezones.json';
import { TTimezoneInfo } from '@/_types/timezone-info.type';
import { Validations } from 'vuelidate-property-decorators';
import { TVuelidateRuleSet } from '@/_types/vuelitation-rule-set.type';
import { required } from 'vuelidate/lib/validators';

import iconHome from '@/_modules/icons/components/sidebar/icon-home.vue';
import iconInfo from '@/_modules/icons/components/sidebar/icon-info.vue';
import IconProgram from '@/_modules/icons/components/sidebar/icon-program.vue';
import iconHall from '@/_modules/icons/components/sidebar/icon-hall.vue';
import iconContacts from '@/_modules/icons/components/sidebar/icon-contacts.vue';
import iconMeetings from '@/_modules/icons/components/sidebar/icon-meetings.vue';
import iconDiscussions from '@/_modules/icons/components/sidebar/icon-discussions.vue';
import iconNotes from '@/_modules/icons/components/sidebar/icon-notes.vue';
import iconResult from '@/_modules/icons/components/sidebar/icon-result.vue';
import iconTextChats from '@/_modules/icons/components/sidebar/icon-text-chats.vue';
import iconNews from '@/_modules/icons/components/sidebar/icon-news.vue';

import _cloneDeep from 'lodash.clonedeep';
import { TranslateResult } from 'vue-i18n';
import DatepickerHelper, {TMuseUIDatepickerDateTimeFormat} from '@/_helpers/datepicker.helper';
import draggable from 'vuedraggable';
import {TDragOptions} from '@/_types/drag-options.type';

export const MENU_PROPERTIES_KEY_NAME = 'eventMenuItemProperties';

const isValidEndDateNotInPast = (endDate: Date, date_start: Date): boolean => { // TODO: move this to helper
  if (date_start && endDate) {
    return true;
  }

  if (date_start && endDate) {
    const _startDate: number = new Date(date_start).getTime() / 1000;
    const _dateEnd = new Date(endDate).getTime() / 1000;
    return _dateEnd > _startDate;
  } else {
    return true;
  }
};

type TFormData = {
  title?: string;
  date_start: Date;
  date_end: Date;
  event_type_id?: number;
  languages?: string[];
  time_region: string;
}

type TSideBarMenuItemProperties = {
  isShown: boolean;
  sorting?: number;
}

type TSideBarMenuManagedItem = {
  properties?: TSideBarMenuItemProperties;
  title?: TranslateResult;
  iconComponentName: string;
}

@Component({
  components: {
    EventLanguageSelector,
    iconHome,
    iconInfo,
    IconProgram,
    iconHall,
    iconContacts,
    iconMeetings,
    iconDiscussions,
    iconNotes,
    iconResult,
    iconTextChats,
    iconNews,
    draggable
  },
  computed: {
    ...mapState('eventStore', ['categoryList', 'typeList']),
    ...mapGetters({
      event: '_eventStore/event',
      eventSettings: '_eventStore/eventSettings',
      myself: 'promoPageStore/contact',
    }),
  }
})
export default class CabinetEventSettings extends Vue {

  @Validations()
  public readonly validations: TVuelidateRuleSet<TFormData> = {
    formData: {
      title: {
        required,
      },
      date_start: {
        required,
      },
      date_end: {
        required,
        isValidEndDate(date_end: Date, formData: TFormData): boolean {
          return isValidEndDateNotInPast(date_end, formData.date_start);
        }
      },
      time_region: {
        required,
      }
    },
  }

  public languages: string[] = [];
  public formData: TFormData = {
    title: '',
    date_start: null,
    date_end: null,
    event_type_id: null,
    time_region: ''
  };

  public endDateMinimum: Date = new Date();
  public isShowStartDate: boolean = false;
  public isShowEndDate: boolean = false;
  public isShowTimezoneLocation: boolean = false;
  public isShowEventType: boolean = false;
  public isMultipleLanguages: boolean = false;
  public isSendSuccess: boolean = false;
  public updatedHintText: TranslateResult = '';
  public timezone: TTimezoneInfo[] = timezone;
  public timezoneLocation: TTimezoneInfo = {} as TTimezoneInfo;
  public allowEventSettingsReRendering: boolean = true;
  public defaultScreen: string[] = []

  public draggableMenuItems: {[key: string]: TSideBarMenuManagedItem}[] = []
  public menuList: {} = {};

  public isDragInProgress: boolean = false;
  public dragOptions: TDragOptions = {
    animation: 200,
    group: 'menuList',
    disabled: false,
  };
  public defaultPage: string = ''
  public menuItems: {[key: string]: TSideBarMenuManagedItem} = {
    // 'event_info': {
    //   properties: {
    //     isShown: true,
    //   },
    //   title: this.$t('sideBar.info'),
    //   iconComponentName: 'icon-home',
    // },
    promo_live: {
      properties: {
        isShown: true,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.live'),
      iconComponentName: 'icon-info',
    },
    news: {
      properties: {
        isShown: true,
      },
      title: this.$t('sideBar.news'),
      iconComponentName: 'icon-news',
    },
    promo_program: {
      properties: {
        isShown: true,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.program'),
      iconComponentName: 'icon-program',
    },
    promo_page_events_companies: {
      properties: {
        isShown: true,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.hall'),
      iconComponentName: 'icon-hall',
    },
    promo_contacts: {
      properties: {
        isShown: true,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.contacts'),
      iconComponentName: 'icon-contacts',
    },
    text_chats: {
      properties: {
        isShown: true,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.textChats'),
      iconComponentName: 'icon-text-chats',
    },
    notes_list: {
      properties: {
        isShown: true,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.notes'),
      iconComponentName: 'icon-notes',
    },
    promo_page_calendar: {
      properties: {
        isShown: true,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.calendar'),
      iconComponentName: 'icon-meetings',
    },
    result: {
      properties: {
        isShown: true,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.result'),
      iconComponentName: 'icon-result',
    },
  };

  public readonly event: TEvent;
  public readonly myself: TContact;
  public readonly eventSettings: TEventSettings;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isEventOrganizer(): boolean {
    if (this.event && this.myself && this.myself.user) {
      return this.event.creator_user_id === this.myself.user.id;
    }
    return false;
  }

  public get menuItemKeyNames(): string[] {
    return Object.keys(this.menuItems);
  }

  @Watch('event', { immediate: true, deep: true })
  private onEventInfoChange(): void {
    if(!this.event) { return; }

    this.formData.title = this.event.title;
    this.formData.date_start = new Date(this.event.date_start);
    this.formData.date_end = new Date(this.event.date_end);
    this.formData.event_type_id = this.event.event_type.id;
    this.formData.time_region = this.event.time_region;
    this.languages = this.event.languages;
    this.formData.time_region = this.event.time_region;

    for (let i = 0; i < timezone.length; i++) {
      if(timezone[i].utc.indexOf(this.formData.time_region) > -1) {
        this.timezoneLocation = timezone[i];
      }
    }

  }

  @Watch('eventSettings', {immediate: true})
  private onEventSettingsChange(): void {
    this.initEventMenuItemProperties();
  }

  @Watch('draggableMenuItems', {immediate: true})
  private onDraggableMenuItemsChange(): void {

    const items = {};

    for (let i = 0; i < this.draggableMenuItems.length; ++i) {
      const key = Object.getOwnPropertyNames(this.draggableMenuItems[i])[0];
      if (this.draggableMenuItems[i]) {
        Object.assign(items, this.draggableMenuItems[i]);
        // @ts-ignore
        items[key].properties.sorting = i + 1;
      }
    }

    this.menuItems = items;
  }

  @Watch('timezoneLocation', {immediate: true})
  private onTimezoneLocationChanged(): void {
    if(this.timezoneLocation && !this.timezoneLocation.utc) { return; }
    this.formData.time_region = this.timezoneLocation.utc[0];
  }

  public created(): void {
    this.updateEventTypes();
  }

  private async updateEventTypes(): Promise<void> {
    await this.$store.dispatch('eventStore/eventType');
  }

  private initEventMenuItemProperties(): void {

    if (!this.allowEventSettingsReRendering) {
      return;
    }

    const defaultProperties: TSideBarMenuItemProperties = {
      isShown: true
    };

    if (this.eventSettings && this.eventSettings.layout && this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME]) {
      this.defaultScreen = this.eventSettings.layout.defaultScreen ? [this.eventSettings.layout.defaultScreen] : [];

      const keynamesFromStore: string[] = Object.keys(this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME]);
      for (let i = 0; i < keynamesFromStore.length; i++) {
        const key: string = keynamesFromStore[i];
        if (Object.prototype.hasOwnProperty.call(this.menuItems, key)) {
          this.menuItems[key].properties = _cloneDeep(this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME][key]);
          if(!this.menuItems[key].properties.sorting) {
            this.menuItems[key].properties.sorting = i + 1;
          }
        }
      }
    } else {
      const keynamesLocal = this.menuItemKeyNames;
      for (let i = 0; i < keynamesLocal.length; i++) {
        this.menuItems[keynamesLocal[i]].properties = _cloneDeep(defaultProperties);
      }
    }

    this.menuItems = Object.assign(this.menuItems, {});
    this.draggableMenuItems = Object.entries(this.menuItems).map((e) => ( { [e[0]]: e[1] } ));
    this.draggableMenuItems.sort((a, b) => {
      if (Object.entries(a)[0][1].properties.sorting < Object.entries(b)[0][1].properties.sorting) {
        return -1;
      } else if (Object.entries(a)[0][1].properties.sorting > Object.entries(b)[0][1].properties.sorting) {
        return 1;
      }
      return 0;
    });

  }

  private autoSetEndDate(val: Date): void {

    const start: number = new Date(this.formData.date_start).getTime() / 1000;
    const end: number = new Date(this.formData.date_end).getTime() / 1000;

    // minimum end date is 1 hour ahead, used in the :min-date prop
    this.endDateMinimum = new Date(start + 60 * 60 * 1000);

    // val has to be present and be a JS Date object. Simple check using getTime
    if (val && ('getTime' in val)) {
      if (!this.formData.date_end || start > end) {
        const date_end = new Date(val);
        date_end.setHours(23, 59, 59);

        // If auto-set date_end time is closer than 1 hour, move it to next day 23:59
        if (date_end.getTime() - val.getTime() < (60 * 60 * 1000)) {
          date_end.setTime(date_end.getTime() + 1000 * 60 * 60 * 24);
        }
      }
    }
  }

  private checkEndDate(val: Date): void {
    if (this.formData.date_start === this.formData.date_end) {
      const startDate = new Date(this.formData.date_end);

      // Set hours to date_start.hours + 1
      if (val.getTime() - +startDate < 60 * 60 * 1000) {
        this.formData.date_end = new Date(startDate.getTime() + 60 * 60 * 1000);
      }
    }
  }

  private getCalendarDateTimeFormat(): TMuseUIDatepickerDateTimeFormat {
    return DatepickerHelper.getMuseUIDatepickerDateTimeFormat();
  }

  private firstDayOfWeek(): number {
    return DatepickerHelper.getFirstDayOfWeekNumber();
  }

  private async patchEvent(): Promise<void> {
    this.$v.formData.$touch();

    if (!this.formData || this.$v.formData.$pending || this.$v.formData.$invalid) {
      return;
    }

    const payload = {
      eventId: this.eventId,
      formData: Object.assign({}, this.formData, {
        date_start: DateTimeHelper.dateToApiDate(this.formData.date_start),
        date_end: DateTimeHelper.dateToApiDate(this.formData.date_end),
      }),
    };

    const result = await this.$store.dispatch('_eventStore/editEvent', payload);
    if (result) {
      this.$v.$reset();
      this.isSendSuccess = true;
      this.allowEventSettingsReRendering = false; // AW-1788 small UX bug #3 from comments
      await this.$store.dispatch('_eventStore/refresh');
      this.allowEventSettingsReRendering = true;
      this.updatedHintText = this.$t('eventPage.edit.updatedSuccess');
    } else {
      this.isSendSuccess = false;
      this.updatedHintText = this.$t('eventPage.edit.updatedError');
    }

    setTimeout(() => {
      this.updatedHintText = '';
    }, 3000);
  }

  // TODO: refactor
  private patchEventSettings(): void {
    const preparedSettings: {[key: string]: TSideBarMenuItemProperties} = {};
    for (let i = 0; i < this.menuItemKeyNames.length; i++) {
      preparedSettings[this.menuItemKeyNames[i]] = this.menuItems[this.menuItemKeyNames[i]].properties;
    }
    let result: {[key: string]: any} = {
      [MENU_PROPERTIES_KEY_NAME]: preparedSettings
    };
    if (this.eventSettings && this.eventSettings.layout) {
      result = { ..._cloneDeep(this.eventSettings.layout), ...result};

      if(this.defaultScreen[0]) {
        result.defaultScreen = !this.menuItems[this.defaultScreen[0]].properties.isShown ? '' : this.defaultScreen[0];
      } else {
        result.defaultScreen = '';
      }
    }
    this.$store.dispatch('_eventStore/patchEventSettings', {eventId: this.eventId, layout: result, show_meetings: this.eventSettings.show_meetings});
  }

  private makeDisabled(value: string): boolean {

    return !(!this.defaultScreen.length || value === this.defaultScreen[0]) || !this.menuItems[value].properties.isShown;
  }

  private onDragStart(): void {
    this.isDragInProgress = true;
  }

  private onDragEnd(): void {
    this.isDragInProgress = false;
    this.saveListSorting();
  }

  private async saveListSorting(): Promise<void> {
    this.patchEventSettings();
  }

}
